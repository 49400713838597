<template>
    <div>
        <div class="giga-article_header">
            <div class="giga-article_thumb" :style="{'background-image': `url(data:image/png;base64,${article.img})`}">
              <!-- <img :src="`data:image/png;base64,${article.img}`" alt="img"> -->
            </div>
            <h2 class="giga-article_title">{{ article.title }}</h2>
            <span class="giga-article_posted">{{ moment(article.date_creation).format('DD MMMM yy') }}</span>
        </div>
        <div class="giga-article_content" v-html="article.content"/>
    </div>
</template>
 
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    name: "Article",
    data: () => {
      return {
        article: null,
      }
    },
    mounted(){
      this.article = this.$route.params.article;
        // this.getArticleByIdAction({ id: parseInt(this.$route.params["id"], 10) })
    },
    computed:{
        ...mapGetters("regulation", [
            "getArticle"
        ])
    },
    methods:{
        ...mapActions("regulation", [
            "getArticleByIdAction"
        ])
    },

}
</script>
